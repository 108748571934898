import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { isBrowser } from '~/helpers';
import Loader from '~/shared/loader/loader.component';

export default function Login() {
    const [isSigningIn, setSigningIn] = useState(false);
    const router = useRouter();

    useEffect(() => {
        async function init() {
            if (!router.isReady || !isBrowser || isSigningIn) {
                return null;
            }

            if (typeof router.query.otp !== 'string' || typeof router.query.redirectTo !== 'string') {
                return router.push('/');
            }

            setSigningIn(true);

            await signIn(
                'identity-server4-otp',
                {
                    callbackUrl: router.query.redirectTo,
                },
                {
                    otp: router.query.otp,
                }
            );
        }

        init();
    }, [router, isSigningIn]);

    return <Loader fixed />;
}
